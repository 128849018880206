

























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import NavbarUser from '@/layout/NavbarUser.vue'
import { CartStore, CartStep } from '~/store/cart'
import { IS_MARKETPLACE, IS_ELITEPAGE } from '~/const/environment'

@Component({ components: { NavbarUser } })
export default class CartNavbar extends Vue {
    CartStep = CartStep

    @CartStore.State('cartStep') cartStep: CartStep

    logoClicked() {
        if (IS_MARKETPLACE) return this.$router.push({ name: 'Home' })
        else if (IS_ELITEPAGE) return this.$router.back()
    }
}
